import React from 'react';
import { Row, Spin, Col, Space } from 'antd';
import './spin.css';

export const Spinner = ({ textLoad }) => {
  return (
    <Row justify="center" align="middle" style={{marginTop:'20em'}}>
      <Col>
          <Spin style={{width:'4rem', height:'4rem'}}/> 
      </Col>
      <Col>
        { textLoad }
      </Col>
    </Row>
  )
}

export const SpinnerLoad = ({ textLoad }) => {
  return (
    <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center', marginTop: 150 }}>
      <Spin style={{ width: '4rem', height: '4rem' }} />
    </Space>
  )
}