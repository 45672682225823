import './App.css';
import "antd/dist/antd.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component, Suspense } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { SpinnerLoad } from './component/atom/spinner';

// containers 
const TheLayout = React.lazy(() => import('./containers/templates/light-mode/layout'));

// Andone Solar 
// const LayoutAndone = React.lazy(() => import('./containers/templates/andon/AndonSolarLayout'));

// pages solar
const Login = React.lazy(() => import('./containers/page/login'))
const ProductivityPage = React.lazy(() => import('./containers/page/andonsolar/solar-productivity'));
const AllPlantProductivityPage = React.lazy(() => import('./containers/page/andonsolar/all-plant-productivity'));
const ElectricSupplyPage = React.lazy(() => import('./containers/page/andonsolar/electric-supply-usage'));
const ElectricCostPage = React.lazy(() => import('./containers/page/andonsolar/electric-cost-usage'));
const PerformancePage = React.lazy(() => import('./containers/page/andonsolar/solar-performance'));
const EmissionAnualMonitoring = React.lazy(() => import('./containers/page/andonsolar/emission-anual-monitoring'));
const EmissionMonthlyMonitoring = React.lazy(() => import('./containers/page/andonsolar/emission-monthly-monitoring'));
const Log = React.lazy(() => import('./containers/page/afterLoginPage/admin/log'));

class App extends Component {

  render() {
    return (
      <>
        <HashRouter>
          <Suspense fallback={<SpinnerLoad />}>
            <Switch>
              <Route exact path="/solar-productivity" name="SolarProductivity" render={props => <ProductivityPage {...props} />} />
              <Route exact path="/all-plant-productivity" name="AllPlantProductivity" render={props => <AllPlantProductivityPage {...props} />} />
              <Route exact path="/electric-supply-usage" name="SolarElectric" render={props => <ElectricSupplyPage {...props} />} />
              <Route exact path="/electric-cost-usage" name="SolarElectric" render={props => <ElectricCostPage {...props} />} />
              <Route exact path="/solar-performance" name="SolarPerformance" render={props => <PerformancePage {...props} />} />
              <Route exact path="/emission-anual-monitoring" name="EmissionMonitoring" render={props => <EmissionAnualMonitoring {...props} />} />
              <Route exact path="/emission-monthly-monitoring" name="EmissionMonitoring" render={props => <EmissionMonthlyMonitoring {...props} />} />
              <Route exact path="/login" name="Login" render={props => <Login {...props} />} />
              <Route exact path="/log" name="log" render={props => <Log {...props} />} />
              {/* <Route exact path="/" name="SolarProductivity" render={props => <ProductivityPage {...props} />} /> */}
              {/* <Route exact path="/solar-productivity/:area?/:page?" name="SolarProductivity" render={props => <SolarProductivity {...props}/>} /> */}
              <Route exact path="/solar-productivity/:area" name="SolarProductivity" render={props => <ProductivityPage {...props} />} />
              <Route exact path="/electric-supply-usage/:area" name="SolarElectric" render={props => <ElectricSupplyPage {...props} />} />
              <Route exact path="/electric-cost-usage/:area" name="SolarElectric" render={props => <ElectricCostPage {...props} />} />
              <Route exact path="/solar-performance/:area" name="SolarPerformance" render={props => <PerformancePage {...props} />} />
              <Route exact path="/emission-anual-monitoring/:area" name="EmissionMonitoring" render={props => <EmissionAnualMonitoring {...props} />} />
              <Route exact path="/emission-monthly-monitoring/:area" name="EmissionMonitoring" render={props => <EmissionMonthlyMonitoring {...props} />} />
              <Route path="/" name="Home" render={props => <TheLayout {...props} />} />
            </Switch>
          </Suspense>
        </HashRouter>
      </>
    )
  }
}

export default App;
